
























import { Component, Prop, Vue } from 'vue-property-decorator';
import CoreStoreMutations from '@/constants/store/modules/core/mutations';
import { namespace } from 'vuex-class';
import FilterRendererNumberRange from '@/typings/filters/types/FilterRendererNumberRange';
import FilterRenderers from '@/constants/filters/FilterRenderers';

const coreStoreModule = namespace('core');

@Component({})
export default class FilterNumberRange extends Vue {
  @coreStoreModule.Mutation[CoreStoreMutations.SET_FILTERS_RENDERER_DATA]: (
    // eslint-disable-next-line max-len
    data: { rowIndex: number, columnIndex: number, filterIndex: number, data: any, filterRenderer: FilterRenderers }
  ) => void;

  @Prop({ required: true, type: Number })
  rowIndex!: number;

  @Prop({ required: true, type: Number })
  columnIndex!: number;

  @Prop({ required: true, type: Number })
  filterIndex!: number;

  @Prop({ required: true, type: Object as () => FilterRendererNumberRange })
  filter!: FilterRendererNumberRange;

  @Prop({ type: Object as () => FilterRenderers })
  filtersRendererType!: FilterRenderers;

  get valueFrom() {
    return this.filter.data.from;
  }

  set valueFrom(from: number) {
    this[CoreStoreMutations.SET_FILTERS_RENDERER_DATA]({
      rowIndex: this.rowIndex,
      columnIndex: this.columnIndex,
      filterIndex: this.filterIndex,
      data: { from, to: this.filter.data.to },
      filterRenderer: this.filtersRendererType,
    });
  }

  get valueTo() {
    return this.filter.data.to;
  }

  set valueTo(to: number) {
    this[CoreStoreMutations.SET_FILTERS_RENDERER_DATA]({
      rowIndex: this.rowIndex,
      columnIndex: this.columnIndex,
      filterIndex: this.filterIndex,
      data: { from: this.filter.data.from, to },
      filterRenderer: this.filtersRendererType,
    });
  }
}
